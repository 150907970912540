import React, { Component } from "react";
import { Row, Breadcrumb, Button, Form, message, DatePicker, Modal, Input, Select, Col, Radio, Popover, Table, TreeSelect, Tree, Cascader } from "antd";
import { AreaSelect } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import FilePreview from "../../common/filePreview";
import moment from "moment";
import { specialProvinceList } from "../../common/cityListBigData";
import { bindActionCreators } from "redux";
import axios from "axios";
import JSZip, { filter } from "jszip";
import FileSaver from "file-saver";
import { change_operInfo } from "../../redux/actions/operInfo";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { AreaSelectForm, ImgViewer, popoverTable, rules } from "../../common";
const { TreeNode } = TreeSelect;

const { Option } = Select;
const RangePicker = DatePicker.RangePicker;
let setPageData = {},
  sendBody = {},
  Timer;
let BackgroundObj = {
  pptx: "rgb(57,58,61)",
  ppt: "rgb(57,58,61)",
  docx: "rgb(243,242,241)",
  doc: "rgb(243,242,241)",
  pdf: "rgb(57,58,61)",
};
let TopObj = {
  pdf: "0px",
};
class DocumentManage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      selectedRowKeys: [],
      docKindsMap: [],
      url: "",
      visibleVideo: false,
      selectedKeys: ["1"],
      currentPage: window.routerCache.DocumentManage.currentPage || 1,
      selectedKeyList: {},
      nameType: "",
      categoryName: "",
      ModalSize: false,
    };
    sendBody = window.routerCache.DocumentManage.sendBody || {};
    setPageData = {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  docKindList = async is => {
    try {
      let res = await Axios("get", "/legalCloud/doc/docKindList", { kindBelong: 1 });
      if (res.code == "200") {
        let arr = this.treeData(res.data, 0);
        arr.map(v => {
          return this.treeData1(v, v.userRole, v.title);
        });

        if (is) {
          let selected = [];
          if (arr.length) {
            selected = arr[0].id;
          }
          this.setState({
            title1: arr[0].title1,
            Edit: arr[0],
            selectedKeys: [selected],
            docKindsMap: arr || [],
            EditTitle: res.data || [],
          });
          sendBody.docKind = selected;
          this.refreshTable();
        } else {
          this.setState({
            docKindsMap: arr || [],
            EditTitle: res.data || [],
          });
        }
      }
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };
  treeData1 (list, parentId, title) {
    if (list.children && list.children.length) {
      list.children.map(v => {
        v.title1 = title + ">" + v.title;
        v.userRole1 = parentId;
        v.title2 = title;
        this.treeData1(v, parentId, v.title1);
      });
    }
    list.title1 = title;
    list.userRole1 = parentId;
    return list;
  }
  treeData (list, parentId) {
    var arr = [];
    list.forEach(item => {
      item.title = item.categoryName;
      item.key = item.id;
      item.parentId = item.parentId || null;
      if (item.parentId * 1 === parentId) {
        const children = this.treeData(list, item.id);
        if (children.length) {
          item.children = children;
        }
        arr.push(item);
      }
    });
    return arr;
  }
  refreshTableList = async () => {
    try {
      let res = await Axios("get", "/legalCloud/doc/list", {
        pageNum: "1",
        pageSize: "10000",
      });
      if (res.code == "200") {
        let obj = {};
        res.data.dataList.map(v => {
          if (v.signRead == "0") {
            obj[v.docKind] = "1";
          }
        });
        this.setState({
          selectedKeyList: obj,
        });
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      let send = sendBody;
      // send.workType = window.routerCache.DocumentManage.send_workType || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/legalCloud/doc/list", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
      });
      setPageData[this.state.currentPage + ""] = res.data.dataList;
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async pagination => {
    this.setState({ loading: true });
    // if (Object.keys(setPageData).includes(pagination.current + "")) {
    //     this.setState({
    //         loading: false,
    //         tData: setPageData[pagination.current + ""],
    //         currentPage: parseInt(pagination.current),
    //     });
    // } else {
    try {
      let send = sendBody;
      send.pageNum = pagination.current + "";
      send.pageSize = "10";
      if (this.state.selectedKeys.length) {
        send.docKind = this.state.selectedKeys[0];
      }
      let res = await Axios("get", "/legalCloud/doc/list", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
        currentPage: pagination.current,
      });
      setPageData[pagination.current + ""] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
      this.setState({ loading: false });
    }
    // }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    sendBody.docKind = this.state.selectedKeys[0];
    this.refreshTableList();
    this.docKindList(true);
    window.winhcFn.changeDept = () => {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.refreshTable();
        }
      );
    };
  }

  onSelect = (selectedKeys, e) => {
    if (e.node) {
      //存储当前节点名称
      this.setState({
        categoryName: e.node.categoryName,
      });
    }
    if (selectedKeys.length) {
      sendBody.docKind = selectedKeys[0];
      this.setState(
        {
          selectedKeys,
          currentPage: 1,
          selectedRowKeys: [],
        },
        () => {
          this.refreshTable();
        }
      );
    }
  };
  readChange = async id => {
    try {
      let res = await Axios("post", "/legalCloud/doc/read", { id });
      if (res.code == "200") {
        this.refreshTableList();
        this.refreshTable();
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };
  componentWillUnmount () {
    window.routerCache.DocumentManage.currentPage = this.state.currentPage;
    window.routerCache.DocumentManage.sendBody = sendBody;
    if (Timer) {
      clearTimeout(Timer);
    }
  }
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  handleBatchDownload = async () => {
    if (!this.state.selectedRowKeys.length) {
      message.info("请先选择要下载的文件");
      return;
    }
    const data1 = this.state.selectedRowKeys;
    const data = [];
    const arr = [];
    data1.map(v => {
      data.push(v.split("&-&")[0]);
      arr.push(v.split("&-&")[1]);
    });
    this.readChange(arr);
    message.loading("正在下载，请稍后...");
    const zip = new JSZip();
    const cache = {};
    const promises = [];
    await data.forEach((item, key) => {
      const promise = this.getFile(item).then(dta => {
        // 下载文件, 并存成ArrayBuffer对象
        // 获取文件名
        let arr = item.split("/");
        const file_name = key + arr[arr.length - 1];
        zip.file(file_name, dta, { binary: true }); // 逐个添加文件
        cache[file_name] = dta;
      });
      promises.push(promise);
    });
    Promise.all(promises).then(() => {
      zip
        .generateAsync({ type: "blob" })
        .then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, `${!this.state.categoryName ? "赢火虫-代理商系统文件" : "赢火虫-" + this.state.categoryName + "文件"}.zip`); // 利用file-saver保存文件
          message.success("操作成功");
          this.setState({ selectedRowKeys: [] });
        })
        .catch(err => {
          message.error("网络出了点小问题，请稍后再试！");
          throw err;
        });
    });
  };
  getFile = url => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
        withCredentials: false, //跨域请求是否提供凭据信息需设置为false，要不然跨域
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error.toString());
        });
    });
  };
  render () {
    const { refreshTable } = this;
    const { url, selectedRowKeys, selectedKeyList, ModalSize } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: !record.isCanDown == 0,
      }),
    };
    let Tstyle = {
      display: "flex",
      background: "red",
      color: "white",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      marginTop: "-5px",
    };
    let columns = [
      {
        title: "ID",
        fixed: "left",
        dataIndex: "id",
        // width: 50,
      },
      {
        title: "文件名称",
        dataIndex: "docName",
        // width: 160,
        render: (text, r) => (
          <div style={{ cursor: "pointer" }}>
            {text && text.length > 9 ? (
              <Popover
                content={
                  <div
                    style={{
                      padding: "10px",
                      maxWidth: "400px",
                      fontSize: "14px",
                      wordBreak: "break-word",
                    }}
                  >
                    {text}
                  </div>
                }
              >
                {
                  <span style={{ display: "flex" }}>
                    {Filter.strLength(text, 9)}
                    {r.signRead == "0" ? <span style={Tstyle}>新</span> : ""}
                  </span>
                }
              </Popover>
            ) : (
              <span style={{ display: "flex" }}>
                {text}
                {r.signRead == "0" ? <span style={Tstyle}>新</span> : ""}
              </span>
            )}
          </div>
        ),
      },
      {
        title: "文件大小",
        dataIndex: "docSize",
        // width: 80,
        render: (t, r) => Filter.getFileSize(t),
      },
      popoverTable(12, {
        title: "备注",
        dataIndex: "describeInfo",
        // width: 120,
      }),
      {
        title: "上传人",
        dataIndex: "operName",
        // width: 120,
      },
      {
        title: "上传时间",
        dataIndex: "uploadDate",
        // width: 180,
        render: (t, r) => Filter.date(t) + " " + Filter.time(r.uploadTime),
      },
      {
        title: "操作",
        dataIndex: "operate",
        // width: 100,
        fixed: "right",
        render: (text, record) => (
          <span>
            {record.isCanDown === 0 ? (
              <a target="_blank" href={record.docUrl} download onClick={() => this.readChange([record.id])}>
                下载
              </a>
            ) : (
              "-"
            )}
            &nbsp;|&nbsp;
            <a
              onClick={() => {
                if (Filter.urlGetFileType(record.docUrl) == "ai" || Filter.urlGetFileType(record.docUrl) == "psd") {
                  message.info("该文件暂不支持预览");
                  return;
                }
                this.setState(
                  {
                    url: record.docUrl,
                    visibleVideo: true,
                  },
                  () => {
                    console.log(document.getElementsByClassName("ReadingToolbarPanel"));
                    // document.querySelector('#ReadingToolbarPanel').style.display = 'none';
                  }
                );
                this.readChange([record.id]);
              }}
            >
              预览
            </a>
          </span>
        ),
      },
    ];
    let offset = document.getElementById("div");
    let Height = offset && offset.offsetHeight;
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>赢火虫商学院</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">赢火虫商学院</span>
          </Col>
        </Row>
        <div className="scrollContainer" id="div">
          <div className="scrollMain">
            <div style={{ backgroundColor: "#fff" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    maxWidth: "29%",
                    minWidth: "29%",
                    height: Height + "px",
                    overflowY: "auto",
                  }}
                >
                  {this.state.docKindsMap && this.state.docKindsMap.length ? (
                    <Tree
                      selectedKeys={this.state.selectedKeys}
                      defaultExpandParent={true}
                      defaultExpandAll={true}
                      autoExpandParent={true}
                      style={{ padding: '12px 0' }}
                      treeData={this.state.docKindsMap}
                      onSelect={this.onSelect}
                      titleRender={node => (
                        <p
                          style={{
                            display: "flex",
                            padding: "0",
                            margin: "0",
                          }}
                        >
                          {node.title} {selectedKeyList[node.key] ? <span style={Tstyle}>新</span> : ""}
                        </p>
                      )}
                    />
                  ) : (
                    "暂无数据"
                  )}
                </div>
                <div style={{ width: "70%" }}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Search refreshTableByData={this.refreshTableByData} docKindsMap={this.state.docKindsMap} />
                    <Button type="primary" onClick={this.handleBatchDownload}>
                      批量下载
                    </Button>
                  </Row>
                  <Table
                    rowKey={record => record.docUrl + "&-&" + record.id}
                    scroll={{ x: "max-content" }}
                    style={{ width: "100%" }}
                    rowSelection={rowSelection}
                    dataSource={this.state.tData}
                    columns={columns}
                    size="middle"
                    pagination={{
                      size: "large",
                      showQuickJumper: true,
                      pageSize: 10,
                      total: parseInt(this.state.totalNum),
                      current: Number(this.state.currentPage),
                    }}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal title={Filter.urlGetFileName(url)} open={this.state.visibleVideo} footer={null} width={ModalSize ? '95%' : '60%'} className={ModalSize ? "ModalIframe" : ''} onCancel={() => this.setState({ visibleVideo: false, ModalSize: false, })} destroyOnClose>
          <div
            style={{
              position: "relative",
              height: '90%'
            }}
          >
            {React.createElement(ModalSize ? FullscreenExitOutlined : FullscreenOutlined, {
              style: {
                position: 'absolute',
                top: '-24px',
                fontSize: '24px',
                right: '0px',
              },
              onClick: () => this.setState({ ModalSize: !ModalSize }),
            })}

            <FilePreview path={url} />
            <div
              style={{
                position: "absolute",
                background: "red",
                top: TopObj[Filter.urlGetFileType(url)] || "50px",
                width: "100%",
                height: "37px",
                background: BackgroundObj[Filter.urlGetFileType(url)],
              }}
            />
          </div>
          <style>{`
                    #ReadingToolbarPanel{
                        display:none !important;
                    }
                    .ModalIframe{
                        top: 2% !important;
                        height: 98%;
                    }
                    .ModalIframe .ant-modal-body { 
                    max-height: none !important;
                          height: 100%;
                    }
                            .ModalIframe .ant-modal-content { 
                  height: 100%;
                    }
                    .ModalIframe .relative{ 
                  height: 100%;
                    }
                             .ModalIframe #video1{ 
                  height: 100%;
                    }
                                     .ModalIframe img{ 
                  height: 100%;
                    }                       .ModalIframe .web-file-iframe{ 
                  height: 100%  !important;
                    }
                  `}</style>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => {
    return state;
  },
  dispatch => ({})
)(DocumentManage);

class SearchM extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      sDate: "",
      eDate: "",
      qingkong: false,
    };
  }

  // 查询
  query = async () => {
    setPageData = {};
    let _this = this;
    let docKind = sendBody.docKind;
    sendBody = this.formRef.current.getFieldsValue();
    _this.props.refreshTableByData([], 1, 1);
    if (sendBody.date1) {
      sendBody.startDate = Filter.timetrans(sendBody.date1[0], "day");
      sendBody.endDate = Filter.timetrans(sendBody.date1[1], "day");
      delete sendBody.date1;
    }
    sendBody.docKind = docKind;
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    try {
      let res = await Axios("get", "/legalCloud/doc/list", sendBody);

      _this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    let docKind = sendBody.docKind;
    this.setState(
      {
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        });
        this.formRef.current.resetFields();
      }
    );
    sendBody = {};
    setPageData = {};
    sendBody.docKind = docKind;
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.DocumentManage.send_workType || '';
    this.props.refreshTableByData([], 1, 1);
    try {
      let res = await Axios("get", "/legalCloud/doc/list", sendBody);
      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () { }

  render () {
    /*控制查询按钮状态*/
    return (
      <div style={{ width: '60%' }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef} style={{ display: "flex" }}>
          <Form.Item label="文件名称" style={{ width: "100%" }} name="docName" initialValue={sendBody.docName}>
            <Input placeholder="文件名称" type="text" />
          </Form.Item>
          <div style={{ display: "flex" }}>
            &emsp;&emsp;
            <Form.Item>
              <Button type="primary" onClick={this.query}>
                查询
              </Button>
            </Form.Item>
            &emsp;&emsp;
            <Form.Item>
              <Button onClick={this.clearOptions}>重置</Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

let Search = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(SearchM);
