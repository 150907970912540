import React, { Component } from "react";
import { Row, Breadcrumb, Button, Form, message, DatePicker, Modal, Input, Select, Col, Radio, Popover, Table, TreeSelect, Cascader, notification } from "antd";
import { AreaSelect, industryList, rules, sourceTypeList, popoverTable, getOnlineList } from "../../common";
import Filter from "../../common/filter";
import { PlusOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import moment from "moment";
import { bindActionCreators } from "redux";
import { Shengpi2 } from "./Common";

import { change_operInfo } from "../../redux/actions/operInfo";
import { change_userInfo } from "../../redux/actions/userInfo";
import { MedicineBoxOutlined, InboxOutlined, ForkOutlined } from "@ant-design/icons";

const { Option } = Select;
const { RangePicker } = DatePicker;
let setPageData = {},
  sendBody = {},
  Timer, loading_exportOrders = false, message_loading = '';

class CaseManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      productList: [],
      currentPage: window.routerCache.CaseManagement.currentPage || 1,
    };
    sendBody = window.routerCache.CaseManagement.sendBody || {};
    setPageData = {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({ tData: data, totalNum: totalNum, currentPage: currentPage }, this.polling);
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });
      let send = sendBody;
      // send.workType = window.routerCache.CaseManagement.send_workType || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      let res = await Axios("get", "/caseBase/page", send);
      this.setState(
        {
          loading: false,
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
        },
        () => {
          this.setState({
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
          });
          this.polling();
        }
      );
      setPageData[this.state.currentPage + ""] = res.data.dataList;
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  getProduct = async () => {
    let res = await Axios("get", "/order/meal/list", {});
    if (res.code === "00") {
      this.setState({
        productList: JSON.parse(res.body),
      });
    } else {
      message.error("获取套餐失败");
    }

    {
    }
  };
  handleTableChange = async pagination => {
    this.setState({ loading: true });
    if (Object.keys(setPageData).includes(pagination.current + "")) {
      this.setState({
        loading: false,
        tData: setPageData[pagination.current + ""],
        currentPage: parseInt(pagination.current),
      });
    } else {
      try {
        let send = sendBody;
        send.pageNum = pagination.current + "";
        send.pageSize = "10";
        let res = await Axios("get", "/caseBase/page", send);
        this.setState(
          {
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
            currentPage: pagination.current,
          },
          this.polling
        );
        setPageData[pagination.current + ""] = res.data.dataList;
      } catch (err) {
        // message.error(err.message);
        this.setState({ loading: false });
      }
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
    this.getProduct();
    window.winhcFn.changeDept = () => {
      this.setState(
        {
          currentPage: 1,
        },
        () => {
          this.refreshTable();
        }
      );
    };
  }
  componentWillUnmount () {
    window.routerCache.CaseManagement.currentPage = this.state.currentPage;
    window.routerCache.CaseManagement.sendBody = sendBody;
    if (Timer) {
      clearTimeout(Timer);
    }
  }
  polling = () => {
    let gonext = false;
    this.state.tData.forEach(item => {
      if (item.status == "0") {
        gonext = true;
        return;
      }
    });
    if (!gonext) {
      return;
    }
    if (Timer) {
      clearTimeout(Timer);
    }
  };
  exportOrders = async () => {
    if (!loading_exportOrders) {
      loading_exportOrders = true;
      message_loading = message.loading('生成中', 0);
      delete sendBody.pageNum
      delete sendBody.pageSize
      try {
        let res = await Axios('post', '/caseBase/caseInfo/poi', sendBody);
        console.log(res)
        if (res.code == '200') {
          notification.success({ message: res.message })
        } else {
          notification.warning({ message: res.message })
        }
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      } catch (error) {
        console.log(error);
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      }
    }
  }
  render () {
    const { userRole } = this.props.operInfo;
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(localStorage.getItem("legal_userInfo") || {});
    const { brokerId } = this.props.userInfo,
      identity = window.sign_requestUrl === "prd" ? 1487 : 380;
    let styleS = {
      display: "flex",
      background: "red",
      color: "white",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      marginTop: "-5px",
      float: "right",
    };
    let columns = [
      {
        key: "0",
        title: "案件ID",
        dataIndex: "caseId",
        render: (text, record, index) => {
          let iconList = [];
          if (record.diagnosisSign == "2") {
            iconList.push(
              <Popover content="诊断案件" key="诊断案件">
                <span
                  style={{
                    width: "20px",
                    display: "inline-block",
                  }}
                >
                  <MedicineBoxOutlined
                    style={{
                      color: "#108ee9",
                      paddingRight: "5px",
                    }}
                  />
                </span>
              </Popover>
            );
          }
          if (record.batchCaseSign == "2") {
            iconList.push(
              <Popover content="总案件" key="总案件">
                <span
                  style={{
                    width: "20px",
                    display: "inline-block",
                  }}
                >
                  <InboxOutlined
                    style={{
                      color: "#4CAF50",
                      paddingRight: "5px",
                    }}
                  />
                </span>
              </Popover>
            );
          } else if (record.batchCaseSign == "3") {
            iconList.push(
              <Popover content="子案件" key="子案件">
                <span
                  style={{
                    width: "20px",
                    display: "inline-block",
                  }}
                >
                  <ForkOutlined
                    style={{
                      color: "#4CAF50",
                      paddingRight: "5px",
                    }}
                  />
                </span>
              </Popover>
            );
          }
          return (
            <div>
              <span
                style={{
                  width: "40px",
                  display: "inline-block",
                }}
              >
                {iconList}
              </span>
              <Link to={"/CaseManagementDetail/" + record.caseId}>
                {text}
                {record.transSign == "1" ? <span style={styleS}>转</span> : ""}
              </Link>
            </div>
          );
        },
        fixed: "left",
      },
      {
        key: "1",
        title: "案件归属",
        dataIndex: "caseBelong",
        render: text => <span>{text == "1" ? "赢火虫" : text == "2" ? "法智特" : ""}</span>,
      },
      {
        title: "电销人员",
        dataIndex: "callOperName",
        render: text => text || "-",
        isShow: brokerId != identity,
      },
      {
        title: "投资经理",
        dataIndex: "saleOperName",
        render: text => text || "-",
        isShow: brokerId != identity,
      },
      popoverTable(8, {
        key: "2",
        title: "原告",
        dataIndex: "creditorName",
      }),
      popoverTable(8, {
        key: "3",
        title: "被告",
        dataIndex: "debtorName",
      }),
      {
        key: "4",
        title: "标签",
        dataIndex: "incaseDealLable",
        render: text => {
          return (
            <span>
              {
                {
                  0: "无人接听",
                  1: "太忙没有时间",
                  2: "补材料",
                  3: "需要考虑",
                  4: "有意向",
                  5: "匹配外部律师",
                }[text]
              }
            </span>
          );
        },
      },
      {
        key: "5",
        title: "等级",
        dataIndex: "caseLevel",
      },
      {
        key: "6",
        title: "案由",
        dataIndex: "caseTypeDesc",
      },
      {
        key: "7",
        title: "标的(万元)",
        dataIndex: "caseAmt",
        render: (text, record, index) => <div>{window._SYT_util.formatMoney(window._SYT_util.math.accDiv(text * 1, 10000), 2)}</div>,
      },
      {
        key: "8",
        title: "合作模式",
        dataIndex: "caseBizModelDesc",
      },
      {
        key: "9",
        title: "内部/外部律师",
        dataIndex: "isOuterLawyer",
      },
      {
        key: "10",
        title: "申请日期",
        dataIndex: "applyDate",
        render: t => (t ? <span> {Filter.date(t)}</span> : ""),
      },
      {
        key: "11",
        title: "通过风控日期",
        dataIndex: "approveDate",
        render: t => (t ? <span> {Filter.date(t)}</span> : ""),
      },
      {
        key: "12",
        title: "签约日期",
        dataIndex: "signDate",
        render: t => (t ? <span> {Filter.date(t)}</span> : ""),
      },
      {
        key: "13",
        title: "推广渠道",
        dataIndex: "appChannel",
        render: t => (t ? <span> {Filter.appChannel(t)}</span> : ""),
      },
      {
        key: "14",
        title: "最后一次跟踪记录",
        dataIndex: "innerTrackDesc",
        render: text => (
          <div style={{ textAlign: "left", cursor: "pointer" }}>
            {text && text.length > 18 ? (
              <Popover
                content={
                  <div
                    style={{
                      padding: "10px",
                      maxWidth: "400px",
                      fontSize: "14px",
                    }}
                  >
                    {text}
                  </div>
                }
              >
                <span style={{ textDecoration: "underline" }}>{Filter.strLength(text, 18)}</span>
              </Popover>
            ) : (
              text
            )}
          </div>
        ),
      },
      {
        key: "15",
        title: "状态",
        dataIndex: "caseStatusDese",
        fixed: "right",
      },
    ];
    if (userRole === "1" || userInfo.job == "senior" || userInfo.job == "director") {
      columns.push({
        title: "操作",
        dataIndex: "timeCreated1",
        fixed: "right",
        render: (text, record) =>
          record.caseStatusDese == "已关闭" || record.caseStatusDese == "异常结案" || record.caseStatusDese == "已结案" ? (
            <span>分配业务员</span>
          ) : (
            <Shengpi2 refreshTable={this.refreshTable} index={"7"} brokerUserId={record.brokerUserId} detailCustId={record.caseId}>
              <a>分配业务员</a>
            </Shengpi2>
          ),
      });
    }
    let Width = 0;
    columns.map(v => {
      Width += v.width * 1;
    });
    columns = columns.filter(v => v.isShow != true);
    console.log(Width, "WidthWidthWidth");
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>案件管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">案件管理</span>
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} productList={this.state.productList} />
            <Row type="flex" justify="space-between" align="middle">
              <div className="tips"></div>
              <Button style={{ marginBottom: 12 }} onClick={this.exportOrders} >生成报表</Button>
            </Row>
            <div style={{ backgroundColor: "#fff" }}>
              <Table
                scroll={{ x: "max-content" }}
                dataSource={this.state.tData}
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return state;
  },
  dispatch => ({})
)(CaseManagement);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      province: "",
      StatusList: [],

      city: "",
      onLineOption: "",
      MoreSearch: false,
    };
  }

  onlineChange = value => {
    let onLineOption = getOnlineList(value);
    this.formRef.current.setFieldsValue({ appid: undefined });
    this.setState({
      onLineOption: onLineOption,
    });
  };
  // 查询
  query = async () => {
    setPageData = {};
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();

    if (sendBody.date) {
      sendBody.startDate = Filter.timetrans(sendBody.date[0], "pattern");
      sendBody.endDate = Filter.timetrans(sendBody.date[1], "pattern");
      delete sendBody.date;
    }
    if (sendBody.date1) {
      sendBody.signStartDate = Filter.timetrans(sendBody.date1[0], "pattern");
      sendBody.signEndDate = Filter.timetrans(sendBody.date1[1], "pattern");
      delete sendBody.date1;
    }
    if (sendBody.date2) {
      sendBody.riskApprovedStartTime = Filter.timetrans(sendBody.date2[0], "pattern");
      sendBody.riskApprovedEndTime = Filter.timetrans(sendBody.date2[1], "pattern");
      delete sendBody.date2;
    }
    _this.props.refreshTableByData([], 1, 1);
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    if (this.state.province) {
      sendBody.province = this.state.province;
    }
    if (this.state.city) {
      sendBody.city = this.state.city;
    }
    if (sendBody.isOnline) {
      if (sendBody.isOnline[1]) {
        sendBody.appid = sendBody.isOnline[1];
      }
      sendBody.isOnline = sendBody.isOnline[0];
    }
    console.log(sendBody);
    try {
      let res = await Axios("get", "/caseBase/page", sendBody);

      _this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    this.setState(
      {
        province: "",
        city: "",
      },
      () => {
        this.formRef.current.resetFields();
      }
    );
    sendBody = {};
    setPageData = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.CaseManagement.send_workType || '';
    this.props.refreshTableByData([], 1, 1);
    try {
      let res = await Axios("get", "/caseBase/page", sendBody);

      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
      setPageData["1"] = res.data.dataList;
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 选择日期范围

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    const { userRole } = this.props.operInfo;
    const job = window.globalData.job;

    if (userRole === "1" || job == "senior") {
      Axios("get", "/brokeruser/page", { pageNum: 1, pageSize: 500 }).then(val => {
        this.setState({
          StatusList: (val.data || {}).dataList || [],
        });
      });
    }
  }

  render () {
    /*控制查询按钮状态*/
    const { StatusList } = this.state;
    const { userRole } = this.props.operInfo;
    const job = window.globalData.job;
    const { brokerId } = this.props.userInfo,
      identity = window.sign_requestUrl === "prd" ? 1487 : 380;
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row type="flex" align="middle">
            <Col span="8">
              <Form.Item label="案件ID" style={{ width: "100%" }} name="caseId" initialValue={sendBody.caseId}>
                <Input placeholder="请输入" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="原告/被告" style={{ width: "100%" }} name="creditorName" initialValue={sendBody.creditorName}>
                <Input placeholder="请输入" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="手机号" style={{ width: "100%" }} name="mobileNo" initialValue={sendBody.mobileNo}>
                <Input placeholder="请输入" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span="8">
              <Form.Item label="申请日期" style={{ width: "100%" }} name="date" initialValue={sendBody.date}>
                <RangePicker style={{ width: "80%" }} allowClear />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="风控通过日期" style={{ width: "100%" }} name="date2" initialValue={sendBody.date2}>
                <RangePicker style={{ width: "80%" }} allowClear />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item label="签约日期" style={{ width: "100%" }} name="date1" initialValue={sendBody.date1}>
                <RangePicker style={{ width: "80%" }} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={
              this.state.MoreSearch
                ? {
                  height: "165px",
                  overflow: "hidden",
                  transition: "all 0.25s",
                }
                : {
                  height: "0px",
                  overflow: "hidden",
                  transition: "all 0.25s",
                }
            }
          >
            <Row type="flex" align="middle">
              <Col span="8">
                <Form.Item label="合作模式" style={{ width: "100%" }} name="caseBizModeCondition" initialValue={sendBody.caseBizModeCondition}>
                  <Select placeholder="请选择合作模式" style={{ width: "80%" }} allowClear>
                    <Option key="1">诉讼</Option>
                    <Option key="3">执行</Option>
                    <Option key="5">债权收购</Option>
                    <Option key="6">非诉清收</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="等级" style={{ width: "100%" }} name="caseLevel" initialValue={sendBody.caseLevel}>
                  <Select placeholder="请选择" style={{ width: "80%" }} allowClear>
                    <Option key="A">A</Option>
                    <Option key="B">B</Option>
                    <Option key="C">C</Option>
                    {/* <Option key='D'>D</Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="状态" style={{ width: "100%" }} name="status" initialValue={sendBody.status}>
                  <Select placeholder="请选择" allowClear style={{ width: "80%" }}>
                    <Option key="1">未提交</Option>
                    <Option key="2">已提交</Option>
                    <Option key="3">待安排风控</Option>
                    <Option key="D">风控审核中</Option>
                    <Option key="4">风控审核通过</Option>
                    <Option key="H">诊断完成</Option>
                    <Option key="G">合同已签约</Option>
                    <Option key="5">已承接</Option>
                    <Option key="0">已关闭</Option>
                    <Option key="8">异常结案</Option>
                    <Option key="9">已结案</Option>
                    <Option key="A">审核拒绝</Option>
                    <Option key="B">待委托</Option>
                    <Option key="C">已委托</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>{" "}
            <Row type="flex" align="middle">
              <Col span="8">
                <Form.Item label="案件归属" style={{ width: "100%" }} name="caseBelong" initialValue={sendBody.caseBelong}>
                  <Select placeholder="请选择案件归属" style={{ width: "80%" }} allowClear>
                    <Option key="1">赢火虫</Option>
                    <Option key="2">法智特</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="案件类型" style={{ width: "100%" }} name="diagnosisSign" initialValue={sendBody.diagnosisSign}>
                  <Select placeholder="请选择案件类型" style={{ width: "80%" }} allowClear>
                    <Option key="1">一般案件</Option>
                    <Option key="2">诊断案件</Option>
                    <Option key="3">诊断转化案件</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="案件来源" style={{ width: "100%" }} name="isOnline" initialValue={sendBody.isOnline}>
                  <Cascader
                    style={{ width: "80%" }}
                    placeholder="请选择"
                    options={[
                      {
                        label: "线上",
                        value: "Y",
                        children: [
                          {
                            label: "APP",
                            value: "app",
                          },
                          {
                            label: "网站",
                            value: "WEB_SITE",
                          },
                          {
                            label: "微信",
                            value: "WX_GZH",
                          },
                          {
                            label: "微信小程序",
                            value: "YHC_MP",
                          },
                          {
                            label: "线上其它",
                            value: "ONLINE",
                          },
                          {
                            label: "法江湖",
                            value: "LS19423001",
                          },
                          {
                            label: "携船网",
                            value: "XC",
                          },
                        ],
                      },
                      {
                        label: "线下",
                        value: "N",
                        children: [
                          {
                            label: "安盾网",
                            value: "ANDUN",
                          },
                          {
                            label: "法智特",
                            value: "FASAC",
                          },
                          {
                            label: "后台录入",
                            value: "MIS",
                          },
                          {
                            label: "代理商案件",
                            value: "BROCK_CRM",
                          },
                        ],
                      },
                    ]}
                    changeOnSelect
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" align="middle">
              <Col span="8">
                {userRole === "1" || job == "senior" ? (
                  <Form.Item label="业务员" style={{ width: "100%" }} name="brokerUserId" initialValue={sendBody.brokerUserId}>
                    <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                      <Option value={this.props.userInfo.userId} key={this.props.userInfo.userName || this.props.userInfo.brokerName || ""}>
                        {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                        （当前自己）
                      </Option>
                      {StatusList.map(v => {
                        if (this.props.userInfo.userId != v.userId)
                          return (
                            <Option value={v.userId} key={v.userId}>
                              {v.userName}
                            </Option>
                          );
                      })}
                    </Select>
                  </Form.Item>
                ) : null}
              </Col>
              <Col span="8">
                {brokerId == identity ? (
                  <Form.Item label="电销人员" style={{ width: "100%" }} name="callBrokerUserName" initialValue={sendBody.callBrokerUserName}>
                    <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                      <Option value={this.props.userInfo.userName || this.props.userInfo.brokerName || ""} key={this.props.userInfo.userName || this.props.userInfo.brokerName || ""}>
                        {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                        （当前自己）
                      </Option>
                      {StatusList.map(v => {
                        if (this.props.userInfo.userId != v.userId)
                          return (
                            <Option value={v.userName} key={v.userId}>
                              {v.userName}
                            </Option>
                          );
                      })}
                    </Select>
                  </Form.Item>
                ) : null}
              </Col>
              <Col span="8">
                {brokerId == identity ? (
                  <Form.Item label="投资经理" style={{ width: "100%" }} name="saleBrokerUserName" initialValue={sendBody.saleBrokerUserName}>
                    <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                      <Option value={this.props.userInfo.userName || this.props.userInfo.brokerName || ""} key={this.props.userInfo.userName || this.props.userInfo.brokerName || ""}>
                        {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                        （当前自己）
                      </Option>
                      {StatusList.map(v => {
                        if (this.props.userInfo.userId != v.userId)
                          return (
                            <Option value={v.userName} key={v.userId}>
                              {v.userName}
                            </Option>
                          );
                      })}
                    </Select>
                  </Form.Item>
                ) : null}
              </Col>
            </Row>
          </div>
          <Row type="flex" align="middle">
            <Col span="8"></Col>
            <Col span="8"></Col>
            <Col span={8}>
              <Row type="flex" justify="end" align="middle" style={{ width: "85%", height: "30px" }}>
                <Form.Item style={{ marginRight: "16px" }}>
                  <Button type="primary" onClick={this.query}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.clearOptions}>重置</Button>
                </Form.Item>
                <a
                  style={{ marginBottom: "24px", marginLeft: "12px" }}
                  onClick={() => {
                    this.setState({
                      MoreSearch: !this.state.MoreSearch,
                    });
                  }}
                >
                  {this.state.MoreSearch ? "收起" : "展开"}
                  &nbsp;
                  {this.state.MoreSearch ? <DownOutlined /> : <UpOutlined />}
                </a>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.CaseManagement.workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            ></Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  state => {
    return state;
  },
  dispatch => ({
    change_operInfo: bindActionCreators(change_operInfo, dispatch),
    change_userInfo: bindActionCreators(change_userInfo, dispatch),
  })
)(SearchM);
