import React, { Component } from "react";
import { Row, Breadcrumb, Button, Form, message, Modal, Input, Select, Col, Radio, Popover, Table, notification, Alert, DatePicker, InputNumber, Cascader } from "antd";
import { AreaSelect, popoverTable } from "../../common";
import Filter from "../../common/filter";
import { connect } from "react-redux";
import { PlusOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Axios } from "../../axios";
import filter from "../../common/filter";
import moment from "moment";
import Login from "../login/login";
import { change_operInfo } from "../../redux/actions/operInfo";
import { bindActionCreators } from "redux";
import { Industry } from "../../common/industryNew";
import { specialProvinceList } from "../../common/cityList";

const { Option } = Select;
let sendBody = {}, loading_exportOrders = false, message_loading = '';
const { confirm } = Modal;
const { RangePicker } = DatePicker;
let sortTypeA = "";
let sortA = "";
let userInfos = localStorage.getItem("legal_userInfo") || {};

class OrderQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tData: [],
      loading: false,
      totalNum: 0,
      currentPage: window.routerCache.OrderQuery.currentPage || 1,
      orderByLevel: false,
      orderByLevel1: false,
    };
    sendBody = window.routerCache.OrderQuery.sendBody || {};
  }

  // 获取表格数据
  refreshTableByData = (data, totalNum, currentPage) => {
    this.setState({
      tData: data,
      totalNum: totalNum,
      currentPage: currentPage,
    });
  };
  // 获取表格数据
  refreshTable = async () => {
    try {
      this.setState({
        loading: true,
      });

      let send = sendBody;
      // send.workType = window.routerCache.OrderQuery.send_workType || '';
      // sendBody.status = window.routerCache.OrderQuery.workType_status || '';
      send.pageNum = this.state.currentPage + "";
      send.pageSize = "10";
      if (sortA) {
        send.sortType = sortTypeA;
        send.sort = sortA;
      } else {
        delete send.sortType;
        delete send.sort;
      }
      let res = await Axios("get", "/order/page", send);
      this.setState({
        loading: false,
        tData: res.data.dataList,
        totalNum: res.data.totalNum,
      });
    } catch (err) {
      // message.error(err);
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination, filters, sorter, sorter1) => {
    if (pagination.current != this.state.currentPage) {
      this.setState({ loading: true });
      try {
        let send = sendBody;
        send.pageNum = pagination.current + "";
        send.pageSize = "10";
        if (sortA) {
          send.sortType = sortTypeA;
          send.sort = sortA;
        } else {
          delete send.sortType;
          delete send.sort;
        }
        let res = await Axios("get", "/order/page", send);
        this.setState({
          loading: false,
          tData: res.data.dataList,
          totalNum: res.data.totalNum,
          currentPage: pagination.current,
        });
      } catch (err) {
        // message.error(err.message);
        this.setState({ loading: false });
      }
    } else {
      let sortType = "";
      let sort = "";
      console.log(sorter);
      if (sorter.column && sorter.field == "orderTime") {
        sortType = 2;
        sort = sorter.order;
        this.setState({
          currentPage: 1,
          orderByLevel: sorter.order,
          orderByLevel1: false,
        });
      } else if (sorter.column && sorter.field == "payTime") {
        sortType = 1;
        sort = sorter.order;
        this.setState({
          orderByLevel1: sorter.order,
          currentPage: 1,
          orderByLevel: false,
        });
      } else if (!sorter.column && sorter.field == "orderTime") {
        sortType = 2;
        sort = sorter.order;
        this.setState({
          currentPage: 1,
          orderByLevel: false,
        });
      } else if (!sorter.column && sorter.field == "payTime") {
        sortType = 1;
        sort = sorter.order;
        this.setState({
          orderByLevel1: false,
          currentPage: 1,
        });
      }
      console.log(sortType, sort);
      if (sort) {
        try {
          let send = sendBody;
          send.pageNum = 1 + "";
          send.pageSize = "10";
          send.sortType = sortType;
          send.sort = sort == "ascend" ? 1 : sort == "descend" ? 2 : undefined;
          sortTypeA = send.sortType;
          sortA = send.sort;
          let res = await Axios("get", "/order/page", send);
          this.setState({
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
            currentPage: pagination.current,
          });
        } catch (err) {
          // message.error(err.message);
          this.setState({ loading: false });
        }
      } else {
        try {
          sortTypeA = "";
          sortA = "";
          let send = sendBody;
          send.pageNum = 1 + "";
          send.pageSize = "10";
          delete send.sortType;
          delete send.sort;
          let res = await Axios("get", "/order/page", send);
          this.setState({
            loading: false,
            tData: res.data.dataList,
            totalNum: res.data.totalNum,
            currentPage: pagination.current,
          });
        } catch (err) {
          // message.error(err.message);
          this.setState({ loading: false });
        }
      }
    }
  };

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    this.refreshTable();
  }

  componentWillUnmount () {
    window.routerCache.OrderQuery.currentPage = this.state.currentPage;
    window.routerCache.OrderQuery.sendBody = sendBody;
  }
  showConfirm = orderId => {
    confirm({
      title: "确定要删除该订单吗？",
      icon: <ExclamationCircleFilled style={{ color: "#1890ff" }} />,
      content: "订单删除后，数据不可恢复，请谨慎操作",
      okText: "确认删除",
      maskClosable: true,
      onOk: async () => {
        let res = await Axios("delete", "/order/del/" + orderId, { orderId });
        if (res.errorCode === "200") {
          this.refreshTable();
          message.success(res.errorMsg);
        } else {
          message.error(res.errorMsg);
        }
      },
      onCancel () {
        console.log("Cancel");
      },
    });
  };
  exportOrders = async () => {
    if (!loading_exportOrders) {
      loading_exportOrders = true;
      message_loading = message.loading('生成中', 0);
      delete sendBody.pageNum
      delete sendBody.pageSize
      try {
        let res = await Axios('post', '/order/poi', sendBody);
        console.log(res)
        if (res.code == '200') {
          notification.success({ message: res.message })
        } else {
          notification.warning({ message: res.message })
        }
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      } catch (error) {
        console.log(error);
        loading_exportOrders = false;
        message_loading();
        message_loading = '';
      }
    }
  }
  render () {
    const { brokerId } = this.props.userInfo, identity = window.sign_requestUrl === "prd" ? 1487 : 380
    let Quanxian = true;
    let styleS = {
      display: "flex",
      background: "red",
      color: "white",
      borderRadius: "50%",
      width: "18px",
      height: "18px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      marginTop: "-5px",
    };
    let columns = [
      {
        title: "订单ID",
        dataIndex: "orderId",
        fixed: "left",
      },
      {
        title: "客户名称",
        dataIndex: "companyName",
        fixed: "left",
        render: (text, r) => (
          <div style={{ display: "flex", cursor: "pointer" }}>
            {text && text.length > 11 ? (
              <Popover
                content={
                  <div
                    style={{
                      padding: "10px",
                      maxWidth: "500px",
                      fontSize: "14px",
                    }}
                  >
                    {text}
                  </div>
                }
              >
                <span>{Filter.strLength(text, 11)}</span>
              </Popover>
            ) : (
              text
            )}{" "}
            {r.transSign == "1" ? <span style={styleS}>转</span> : ""}
          </div>
        ),
      },
      popoverTable(7, {
        title: "联系人",
        dataIndex: "linkMan",
      }),
      {
        title: "所属地区",
        dataIndex: "province",
        render: (t, r) => (t || "") + "   " + (r.city || ""),
      },
      {
        title: "代理商",
        dataIndex: "brokerName",
      },
      {
        title: "业务员",
        dataIndex: "userName",
      },
      {
        title: "电销人员",
        dataIndex: "telSaleBrokerUserName",
        render: text => text || "-",
        isShow: brokerId != identity,
      },
      {
        title: "面销人员",
        dataIndex: "faceSaleBrokerUserName",
        render: text => text || "-",
        isShow: brokerId != identity,
      },
      {
        title: "状态",
        dataIndex: "status",
        render: (text, record) => filter.OrderQuerystatus(text),
      },
      {
        title: "套餐/单项服务",
        dataIndex: "specification",
        render: t => Filter.legalspecification(t),
      },
      {
        title: "金额",
        dataIndex: "transAmt",
        render: (text, record) => text + "元",
      },
      {
        title: "创建日期",
        dataIndex: "orderTime",
        sorter: true,
        sortOrder: this.state.orderByLevel,
        render: (text, record) => (text ? text.replace("T", "  ") : "-"),
      },
      {
        title: "购买日期",
        dataIndex: "payTime",
        sorter: true,
        sortOrder: this.state.orderByLevel1,

        render: (text, record) => (text ? text.replace("T", "  ") : "-"),
      },
      {
        title: "开票状态",
        dataIndex: "invoiceStatus",
        fixed: "right",
        render: (t, record) => filter.ifStatus(record || {}) || "-",
      },
      {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        align: "center",
        render: (text, record, index) => (
          <span>
            {!record.businessConfirmTime && record.status == 1 ? <AddBroker refreshTable={this.refreshTable} type="edit" record={record} /> : ""}
            <Link to={"/OrderQueryDetail/" + record.orderId}>查看</Link>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            {["1", "C"].includes(String(record.status)) ? (
              <a onClick={() => this.showConfirm(record.orderId)} style={{ color: "#D9001B" }}>
                删除
              </a>
            ) : (
              "删除"
            )}
          </span>
        ),
      },
    ];
    columns = columns.filter(v => v.isShow != true)
    return (
      <div className="pageContainer">
        <Row className="headNav">
          <Col span={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item>订单管理</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={24} className="title">
            <span className="name">直客订单管理</span>
            {Quanxian ? <AddBroker refreshTable={this.refreshTable} /> : ""}
          </Col>
        </Row>
        <div className="scrollContainer">
          <div className="scrollMain">
            <Search refreshTableByData={this.refreshTableByData} />
            <Row type="flex" justify="space-between" align="middle">
              <div className="tips"></div>
              <Button style={{ marginBottom: 12 }} onClick={this.exportOrders} >生成报表</Button>
            </Row>
            <div style={{ backgroundColor: "#fff" }}>
              <Table
                scroll={{ x: "max-content" }}
                dataSource={this.state.tData}
                rowKey="orderId"
                columns={columns}
                size="middle"
                pagination={{
                  size: "large",
                  showQuickJumper: true,
                  pageSize: 10,
                  total: parseInt(this.state.totalNum),
                  current: Number(this.state.currentPage),
                  showTotal: total => {
                    return "共 " + total + " 条记录 ";
                  },
                }}
                loading={this.state.loading}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => {
    return state;
  },
  dispatch => ({})
)(OrderQuery);

class SearchM extends React.Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      province: sendBody.province || "",
      city: sendBody.city || "",
      qingkong: false,
      StatusList: [],
    };
  }

  // 查询
  query = async () => {
    let _this = this;
    sendBody = this.formRef.current.getFieldsValue();

    if (sendBody.date1) {
      sendBody.fromDateTime = moment(sendBody.date1[0]).format("YYYY-MM-DD HH:MM:SS");
      sendBody.toDateTime = moment(sendBody.date1[1]).format("YYYY-MM-DD HH:MM:SS");
      delete sendBody.date1;
    }
    if (this.state.city) {
      sendBody.city = this.state.city;
    }
    if (this.state.province) {
      sendBody.province = this.state.province;
      if (this.state.province.includes("市")) {
        if (this.state.province.slice(-1) === "市") {
          sendBody.province = this.state.province.substring(2, -1);
        }
      }
    }
    delete sendBody.date;
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    // sendBody.workType = window.routerCache.OrderQuery.send_workType || '';
    // sendBody.status = window.routerCache.OrderQuery.workType_status || '';
    console.log(sortA, "sortA");
    if (sortA) {
      sendBody.sortType = sortTypeA;
      sendBody.sort = sortA;
    } else {
      delete sendBody.sortType;
      delete sendBody.sort;
    }
    try {
      console.log(sendBody);
      let res = await Axios("get", "/order/page", sendBody);

      _this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearOptions = async () => {
    // 清空的时候清空地区
    this.setState(
      {
        province: "",
        city: "",
        qingkong: true,
      },
      () => {
        this.setState({
          qingkong: false,
        });
        this.formRef.current.resetFields();
      }
    );
    sendBody = {};
    sendBody.pageNum = "1";
    sendBody.pageSize = "10";
    if (sortA) {
      sendBody.sortType = sortTypeA;
      sendBody.sort = sortA;
    } else {
      delete sendBody.sortType;
      delete sendBody.sort;
    }
    this.props.refreshTableByData([], 1, 1);
    console.log(sendBody);
    try {
      let res = await Axios("get", "/order/page", sendBody);

      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  clearLawfirm = async () => {
    window.routerCache.OrderQuery.workTypeStr = "";
    window.routerCache.OrderQuery.workType_status = "";
    sendBody.status = "";
    this.props.refreshTableByData([], 1, 1);
    this.setState({
      workType_status: "",
    });
    try {
      let res = await Axios("get", "/BrokerInfos/pageLists", sendBody);
      this.props.refreshTableByData(res.data.dataList, res.data.totalNum, 1);
    } catch (err) {
      // message.error(err.message);
    }
  };
  // 选择日期范围

  // 组件渲染后获取外界数据(GET)
  componentDidMount () {
    const { userRole } = this.props.operInfo;
    const job = window.globalData.job;

    if (userRole === "1" || job == "senior") {
      Axios("get", "/brokeruser/page", { pageNum: 1, pageSize: 500 }).then(val => {
        this.setState({
          StatusList: (val.data || {}).dataList || [],
        });
      });
    }
  }

  render () {
    /*控制查询按钮状态*/
    const { userRole } = this.props.operInfo;
    const { StatusList } = this.state;
    const job = window.globalData.job;
    const { brokerId } = this.props.userInfo, identity = window.sign_requestUrl === "prd" ? 1487 : 380
    return (
      <div className="search_like_antD" style={{ marginTop: "-8px" }}>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={this.formRef}>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item label="客户名称：" style={{ width: "100%" }} name="companyName" initialValue={sendBody.companyName}>
                <Input placeholder="请输入客户名称" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="手机号" style={{ width: "100%" }} name="mobileNo" initialValue={sendBody.mobileNo}>
                <Input placeholder="请输入手机号" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="所属地区：" style={{ width: "100%" }} name="province" initialValue={sendBody.province}>
                <AreaSelect
                  bodyStyle={{ width: "80%" }}
                  province={this.state.province}
                  city={this.state.city}
                  hiddenCountry
                  Hadoop={false}
                  allowClear
                  qingkong={this.state.qingkong}
                  select={(province, city, county) => {
                    console.log(province, city, county);
                    sendBody.province = province;
                    sendBody.city = city;
                    this.setState({
                      province: province || "",
                      city: city || "",
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item label="代理商：" style={{ width: "100%" }} name="brokerName" initialValue={sendBody.brokerName}>
                <Input placeholder="请输入代理商" type="text" style={{ width: "80%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={<span>状态</span>} style={{ width: "100%" }} name="status" initialValue={sendBody.status}>
                <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                  <Option value="0">订单完成</Option>
                  <Option value="1">待支付</Option>
                  <Option value="2">支付成功</Option>
                  <Option value="R">需退款</Option>
                  <Option value="D">已删除</Option>
                  <Option value="G">已退款</Option>
                  <Option value="C">已关闭</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="开始购买时间" style={{ width: "100%" }} name="date1" initialValue={sendBody.date1}>
                <RangePicker showTime={{ format: "HH:mm" }} format="YYYY-MM-DD HH:mm" style={{ width: "80%" }} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}>
              <Form.Item label={<span>开票状态</span>} style={{ width: "100%" }} name="invoiceStatus" initialValue={sendBody.invoiceStatus}>
                <Select style={{ width: "80%" }} placeholder="请选择开票状态" allowClear>
                  <Option value="0">待开票</Option>
                  <Option value="1">已开票</Option>
                  <Option value="2">已作废</Option>
                  <Option value="5">已受理</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              {userRole === "1" || job == "senior" ? (
                <Form.Item label="业务员" style={{ width: "100%" }} name="userName" initialValue={sendBody.userName}>
                  <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                    <Option key={this.props.userInfo.userId} value={this.props.userInfo.userName || this.props.userInfo.brokerName || ""}>
                      {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                      （当前自己）
                    </Option>
                    {StatusList.map(v => {
                      if (this.props.userInfo.userId != v.userId)
                        return (
                          <Option key={v.userId} value={v.userName}>
                            {v.userName}
                          </Option>
                        );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
            <Col span={8}>
              {brokerId == identity ? (
                <Form.Item label="电销人员" style={{ width: "100%" }} name="telSaleBrokerUserId" initialValue={sendBody.telSaleBrokerUserId}>
                  <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                    <Option value={this.props.userInfo.id}>
                      {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                      （当前自己）
                    </Option>
                    {StatusList.map(v => {
                      if (this.props.userInfo.userId != v.userId) return <Option value={v.id}>{v.userName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={8}>
              {brokerId == identity ? (
                <Form.Item label="面销人员" style={{ width: "100%" }} name="faceSaleBrokerUserId" initialValue={sendBody.faceSaleBrokerUserId}>
                  <Select style={{ width: "80%" }} placeholder="请选择" allowClear>
                    <Option value={this.props.userInfo.id}>
                      {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                      （当前自己）
                    </Option>
                    {StatusList.map(v => {
                      if (this.props.userInfo.userId != v.userId) return <Option value={v.id}>{v.userName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null}
            </Col>
            <Col span={8}></Col>
            <Col span={8}>
              <Row type="flex" justify="end" align="middle" style={{ width: "85%", height: "30px" }}>
                <Form.Item style={{ marginRight: "16px" }}>
                  <Button type="primary" onClick={this.query}>
                    查询
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.clearOptions}>重置</Button>
                </Form.Item>
              </Row>
            </Col>
          </Row>
          <div>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={
                window.routerCache.OrderQuery.workTypeStr
                  ? {
                    paddingBottom: "10px",
                  }
                  : { paddingBottom: "40px" }
              }
            >
              {window.routerCache.OrderQuery.workTypeStr ? (
                <Alert
                  message={
                    <div>
                      <span>{window.routerCache.OrderQuery.workTypeStr}</span>
                      &nbsp;&nbsp;
                      <a onClick={this.clearLawfirm}>清空</a>
                      &nbsp;&nbsp;
                    </div>
                  }
                  type="info"
                  showIcon
                  style={{ marginTop: "12px" }}
                />
              ) : (
                ""
              )}
            </Row>
          </div>
        </Form>
        <style>{`
               .search_like_antD .ant-form ant-form-inline .ant-row-flex .ant-row-flex-space-between .ant-row-flex-middle{
                    padding: 0px !important;
                }
                `}</style>
      </div>
    );
  }
}

let Search = connect(
  state => {
    return state;
  },
  dispatch => ({})
)(SearchM);

let Timer,
  alreadySelect = false;
let isBlur = false;
class AddBrokerM extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectOption: [],
      productList: [],
      StatusList: [],
      loading: false,
      confirmLoading: false,
      province: "",
      city: "",
      transAmt: 0,
      ExtraInfo: { isShowDiscount: 1 },
      qingkong: false,
      isTrue: false,
      renewDiscount: [],
      priceData: {
        legal_basic: [8800, 9800, 10800],
        legal_honour: [19800, 21800, 23800],
        legal_normal: [3980],
        legal_honour_pro: [33800],
      },
      isOpen: false,
      custList: [],
      priceInfo: "",
      selectCompanyName: "",
    };
  }

  componentWillMount () { }

  // 单击确定按钮提交表单
  handleSubmit = e => {
    const { userRole } = this.props.operInfo;
    e.preventDefault();
    if (this.props.type == "edit") {
      this.editHandle();
      return;
    }
    let arr = ["businessUserId", "companyId", "companyName", "currentUserId", "linkman", "mobileNo", "payTime", "category", "specification", "transAmt", "firstDemand", "renewDiscount", "companySpec"];

    this.formRef.current
      .validateFields(arr)
      .then(async values => {
        let sendData = values;
        if (values.saleOperId) {
          sendData.saleOperId = values.saleOperId;
          sendData.saleOperName = this.props.baseData.operTree.getOperInfo(values.saleOperId).realName;
        }
        if (this.state.province) {
          sendData.province = this.state.province;
        } else {
          message.warning("请选择地区");
          return;
        }

        if (this.state.city) {
          sendData.city = this.state.city;
        } else {
          message.warning("请选择城市");
          return;
        }
        const job = window.globalData.job;
        if ((userRole === "1" || job == "senior") && !sendData.businessUserId) {
          message.warning("请选择业务员");
          return;
        }
        if (values.category) {
          sendData.firstCategory = values.category[0];
          sendData.secondCategory = values.category[1];
          sendData.thirdCategory = values.category[2];
          sendData.fourCategory = values.category[3];
          delete sendData.category;
        } else {
          message.warning("请选择行业");
          return;
        }
        // if (values.payTime) {
        //     values.payTime = filter.timetrans(values.payTime, 'day') + ' 00:00:00';
        // }

        if (userRole === "1" || job == "senior") {
          sendData.businessUserId = sendData.businessUserId * 1;
        } else {
          sendData.businessUserId = this.props.operInfo.userId;
        }
        // sendData.term = sendData.specification[1].split("-")[2];
        sendData.specification = sendData.specification[1].split("-")[1];
        if (sendData.transAmt) {
          sendData.transAmt = sendData.transAmt * 1 - this.state.renewDiscount * 1;
        }

        if (sendData.companyName.length <= 3) {
          Modal.confirm({
            title: "您确认客户名称是“" + sendData.companyName + "”吗",
            onOk: () => {
              this.AxiosFun(sendData);
            },
            onCancel () {
              console.log("Cancel");
            },
          });
          return;
        }
        console.log(sendData, "sendDatasendData");
        this.AxiosFun(sendData);
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  };

  AxiosFun = async sendData => {
    try {
      this.setState({ confirmLoading: true });
      let res = await Axios("post", "/order/add", sendData);
      if (res.code == "200" || res.code == "00") {
        this.props.refreshTable();
        this.formRef.current.resetFields();
        message.success(res.msg);
        this.setState(
          {
            visible: false,
            qingkong: true,
            confirmLoading: false,
            province: "",
            city: "",
            ExtraInfo: {},
          },
          () => {
            this.setState({
              qingkong: false,
            });
          }
        );
      } else {
        message.error(res.msg);
        this.setState({ confirmLoading: false });
      }
    } catch (err) {
      message.error(err.msg);
      this.setState({ confirmLoading: false });
    }
  };

  //编辑
  async editHandle () {
    try {
      let { record = {} } = this.props;
      if (!this.formRef.current.getFieldValue("transAmt")) {
        return;
      }
      let sendData = {
        orderId: this.props.record.orderId,
        transAmt: this.formRef.current.getFieldValue("transAmt") * 1 - this.state.renewDiscount * 1,
      };
      if (sendData.transAmt * 1 < record.oriAmt) {
        message.info("价格不能低于成本价");
        return;
      }

      this.setState({ confirmLoading: true });
      let res = await Axios("post", "/order/modify", sendData);
      if (res.code == "200" || res.code == "00") {
        this.props.refreshTable();
        this.formRef.current.resetFields();
        this.formRef.current.setFieldsValue({ transAmt: sendData.transAmt });
        message.success(res.msg);
        this.setState(
          {
            visible: false,
            qingkong: true,
            confirmLoading: false,
            province: "",
            city: "",
          },
          () => {
            this.setState({
              qingkong: false,
            });
          }
        );
      } else {
        message.error(res.msg);
        this.setState({ confirmLoading: false });
      }
    } catch (err) {
      message.error(err.msg);
      this.setState({ confirmLoading: false });
    }
  }

  // 弹出框设置
  showModal = () => {
    this.setState({ visible: true });
  };
  handleCancel = e => {
    this.setState({ visible: false });
  };
  handleSelectChange = value => {
    console.log("select=" + value);
  };

  checkData = () => {
    let { record = {} } = this.props;
    if (this.props.type == "edit") {
      this.ExtraInfoFun(record.specification, record.mobileNo);
    }
    this.setState({ visible: true, transAmt: record.oriAmt || 0, priceInfo: (record.packageName || "").includes("单项服务") ? "单项服务" : "" });
    this.getPkgList();
    const job = window.globalData.job;

    if (this.props.operInfo.userRole === "1" || job == "senior") {
      Axios("get", "/brokeruser/page", {
        status: "0", pageNum: 1, pageSize: 500
      }).then(val => {
        this.setState({
          StatusList: (val.data || {}).dataList || [],
        });
      });
    }
  };

  getPkgList = phone => {
    const sendData = {
      companyName: this.state.selectCompanyName,
    };
    if (this.props.type === "edit") {
      sendData.companyName = this.props.record.companyName;
    }
    phone && (sendData.mobileNo = phone);
    Axios("get", "/order/product/group", sendData).then(val => {
      console.log(val.data);
      if (val.data && val.data.length > 0) {
        val.data.map(v => {
          v.name = v.name;
          v.specification = v.name;
          v.winCoinProductVOS.map(i => {
            i.name = `${i.productDetail} ${i.costAmt}元·${i.serviceMode}`;
            i.specification = i.costAmt + "-" + i.specification + "-" + i.serviceMode;
          });
          v.children = v.winCoinProductVOS;
        });
        this.setState({
          productList: val.data,
        });
      }
    });
  };

  onChangePackage = e => {
    console.log(e);
    let mobileNo = this.formRef.current.getFieldValue("mobileNo");
    if (e && e.length) {
      if (e && /^((1[0-9]{2})+\d{8})$/.test(mobileNo)) {
        this.ExtraInfoFun(e, mobileNo);
      }
      this.formRef.current.setFieldsValue({
        transAmt: e[1].split("-")[0],
      });
      this.setState({
        priceInfo: e[0],
        transAmt: e[1].split("-")[0] * 1,
      });
    } else {
      this.setState({ priceInfo: "", transAmt: 0 });
      this.formRef.current.setFieldsValue({ transAmt: undefined });
    }
  };

  ExtraInfoFun = async (e, mobileNo) => {
    try {
      let res = await Axios("get", "/order/extraInfo", {
        specification: e[1].split("-")[1],
        mobileNo,
      });
      if (res.code == "200" || res.code == "00") {
        this.setState({
          ExtraInfo: res.body,
          renewDiscount: res.body.renewDiscount,
        });
      } else {
        message.error(res.msg);
      }
    } catch (err) {
      message.error(err.msg);
    }
  };

  getCustList = async v => {
    if (Timer) {
      clearTimeout(Timer);
    }
    Timer = setTimeout(async () => {
      try {
        let res = await Axios("get", "/custInfos/open/Eci/simple/v2", { companyName: v }, "fireflyErp");
        console.log("企业列表=", res);
        if (res.isSuccess == "T" && res.body) {
          let custList = [];
          JSON.parse(res.body).result.forEach(item => {
            if (item.name) {
              let names = [];
              for (let i = 0; i < item.name.length; i++) {
                let yes = false;
                for (let j = 0; j < v.length; j++) {
                  if (v[j] == item.name[i]) {
                    yes = true;
                    break;
                  }
                }
                if (yes) {
                  names.push(<span style={{ color: "#FD485E" }}>{item.name[i]}</span>);
                } else {
                  names.push(item.name[i]);
                }
              }
              custList.push(
                <Option key={item.keyNo} value={item.name}>
                  {names}
                </Option>
              );
            }
          });
          this.setState({
            custList,
          });
        } else {
          this.setState({
            custList: [],
          });
        }
      } catch (err) {
        if (err && err.message) {
          message.error(err.message);
        }
      }
    }, 700);
  };
  searchCust = async companyId => {
    try {
      let res = await Axios("get", `/legalCloud/open/area/${companyId}`, {}, "cloud-legal");
      if (res.isSuccess == "T" && res.body) {
        if (res.body.province) {
          res.body.province = specialProvinceList.find(e => e.includes(res.body.province));
        }
        let { userInfo = {} } = this.props;
        if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
        console.log(this.props, "userInfouserInfo");
        //设置相应数据
        let city = "";
        if (userInfo.city == "昆山市" && res.body.county == "昆山市") {
          city = "昆山市";
        } else if (userInfo.city == "常熟市" && res.body.county == "常熟市") {
          city = "常熟市";
        } else {
          city = res.body.city;
        }
        this.setState(
          {
            province: res.body.province,
            city,
            isTrue: true,
          },
          () => {
            this.setState({
              isTrue: false,
            });
          }
        );
        this.formRef.current.setFieldsValue({
          category: [res.body.cateFirst, res.body.cateSecond, res.body.cateThird, res.body.cateFour],
        });
      } else {
        this.setState({
          province: "",
          city: "",
        });
        this.formRef.current.setFieldsValue({
          category: undefined,
        });
      }
    } catch (err) { }
  };
  transAmtFun = () => {
    return this.formRef.current.getFieldValue("transAmt") ? this.formRef.current.getFieldValue("transAmt").replace("元", "") * 1 - this.state.renewDiscount * 1 : "";
  };
  render () {
    const { userRole } = this.props.operInfo;
    const { productList, StatusList, priceData, isOpen, priceInfo, transAmt } = this.state;
    const { type, record } = this.props;
    console.log(record);
    const job = window.globalData.job;
    let { userInfo = {} } = this.props;
    if ("{}" == JSON.stringify(userInfo)) userInfo = JSON.parse(userInfos);
    const Cstyle = {
      fontSize: 12,
      color: "#D9001B",
      marginLeft: "25.5%",
      marginTop: "-24px",
    };
    return (
      <span>
        {type == "edit" ? (
          <a style={{ color: "#1890ff", marginRight: 10 }} onClick={this.checkData}>
            修改
          </a>
        ) : (
          <Button type="primary" icon={<PlusOutlined />} style={{ float: "right" }} onClick={this.checkData}>
            新增订单
          </Button>
        )}

        <Modal
          title={type == "edit" ? "编辑订单" : "新增订单"}
          open={this.state.visible}
          onOk={this.handleSubmit}
          confirmLoading={this.state.confirmLoading}
          onCancel={() => {
            this.setState({ visible: false });
          }}
        >
          <Form ref={this.formRef} layout="horizontal" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item
              label="客户名称："
              name="companyName"
              initialValue={type === "edit" ? record.companyName : undefined}
              rules={[
                {
                  required: true,
                  message: "客户名称不能为空",
                },
              ]}
            >
              <Select
                showSearch
                optionLabelProp="value"
                placeholder="请输入客户名称"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                disabled={type === "edit" ? true : false}
                onSearch={v => {
                  setTimeout(() => {
                    if (v) {
                      v = v.replace(/\s/g, "");
                      this.formRef.current.setFieldsValue({
                        companyName: v,
                      });
                      this.getCustList(v);
                    }
                  });
                }}
                notFoundContent={null}
                onSelect={(v, options) => {
                  if (v) {
                    v = v.replace(/\s/g, "");
                  }
                  if (v) {
                    console.log("options==", options);
                    //存储选择的公司
                    this.setState(
                      {
                        selectCompanyName: options.value,
                      },
                      () => {
                        this.getPkgList();
                      }
                    );
                    this.searchCust(options.key);
                  }
                }}
              >
                {this.state.custList}
              </Select>
            </Form.Item>
            <Form.Item
              label="联系人："
              name="linkman"
              initialValue={type === "edit" ? record.linkMan : undefined}
              rules={[
                {
                  required: true,
                  message: "联系人不能为空",
                },
              ]}
            >
              <Input placeholder="请输入联系人" type="text" disabled={type === "edit" ? true : false} maxLength={6} />
            </Form.Item>
            <Form.Item
              label="手机号："
              name="mobileNo"
              initialValue={type === "edit" ? record.mobileNo : undefined}
              rules={[
                {
                  required: true,
                  message: "手机号不能为空",
                },
                {
                  message: "手机号不合法",
                  pattern: /^((1[0-9]{2})+\d{8})$/,
                },
              ]}
            >
              <Input
                onChange={e => {
                  if (/^((1[0-9]{2})+\d{8})$/.test(e.target.value)) {
                    if (this.formRef.current.getFieldValue("specification")) {
                      this.ExtraInfoFun(this.formRef.current.getFieldValue("specification"), e.target.value);
                    }
                    Axios("get", "/order/" + e.target.value, {}).then(val => {
                      this.setState({
                        isOpen: !!((val.errorCode == "200" || val.isSuccess == "T") && val.body.orderId),
                      });
                    });
                  } else {
                    this.setState({ isOpen: false });
                  }
                }}
                onBlur={e => {
                  if (/^((1[0-9]{2})+\d{8})$/.test(e.target.value)) {
                    this.formRef.current.setFieldsValue({
                      specification: undefined,
                      transAmt: undefined,
                    });
                    this.getPkgList(e.target.value);
                  }
                }}
                placeholder="请输入联系人手机号"
                type="text"
                maxLength={11}
                disabled={type === "edit" ? true : false}
              />
            </Form.Item>
            {isOpen && <p style={Cstyle}>已有与该手机号关联的订单，请确认好是否同一订单</p>}
            <Form.Item
              label="所属行业："
              name="category"
              initialValue={type === "edit" ? (record.goodJsonVO.firstCategory ? [record.goodJsonVO.firstCategory, record.goodJsonVO.secondCategory, record.goodJsonVO.thirdCategory, record.goodJsonVO.fourCategory] : null) : null}
              rules={[
                {
                  required: true,
                  message: "请选择行业",
                },
              ]}
            >
              <Cascader options={Industry} disabled={type === "edit" ? true : false} placeholder="请选择" fieldNames={{ label: "name", value: "name" }} expandTrigger="hover" />
            </Form.Item>
            <Form.Item
              label="套餐"
              style={{ width: "100%" }}
              name="specification"
              initialValue={type === "edit" ? Filter.legalspeciName(record.specification, record.oriAmt || record.transAmt) : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择套餐",
                },
              ]}
            >
              <Cascader
                // allowClear={false}
                fieldNames={{
                  label: "name",
                  value: "specification",
                  children: "children",
                }}
                disabled={type === "edit" ? true : false}
                options={productList}
                onChange={this.onChangePackage}
                placeholder="请选择"
              />
            </Form.Item>
            <p style={Cstyle}>（套餐请慎重选择，提交后不可更改）</p>
            <Form.Item
              label="价格"
              style={{ width: "100%" }}
              name="transAmt"
              initialValue={type === "edit" ? record.transAmt : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择价格",
                },
              ]}
            >
              <InputNumber addonAfter="元" style={{ width: "100%" }} maxLength={10} placeholder="请输入价格" disabled={!(priceInfo == "单项服务")} min={transAmt} />
            </Form.Item>
            {priceInfo == "单项服务" && <p style={Cstyle}>此价格为成本价，您可以自行设置其他价格，但是不能低于成本价</p>}
            {this.state.ExtraInfo.isNewUser == "N" && this.state.ExtraInfo.isShowDiscount == "0" ? (
              <Form.Item
                label="续费优惠："
                name="renewDiscount"
                initialValue={this.state.ExtraInfo.renewDiscount || undefined}
                rules={[
                  {
                    required: false,
                    message: "请选择续费优惠",
                  },
                ]}
              >
                <Select
                  placeholder="请选择"
                  allowClear
                  style={{ width: "100%" }}
                  onChange={e => {
                    this.setState({
                      renewDiscount: e || 0,
                    });
                  }}
                >
                  {this.state.ExtraInfo.renewDiscount ? <Option key={this.state.ExtraInfo.renewDiscount}>{this.state.ExtraInfo.renewDiscount}</Option> : ""}
                </Select>
              </Form.Item>
            ) : (
              ""
            )}

            <Form.Item
              label="首要需求："
              name="firstDemand"
              initialValue={type === "edit" ? record.firstDemand : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择首要需求",
                },
              ]}
            >
              <Select placeholder="请选择" allowClear style={{ width: "100%" }} disabled={type === "edit" ? true : false}>
                {/* <Option key={1} value={1}>
                                        应收帐款催款
                                    </Option> */}
                <Option key={2} value={2}>
                  劳动人事咨询
                </Option>
                <Option key={3} value={3}>
                  合同起草
                </Option>
                <Option key={4} value={4}>
                  合同审核
                </Option>
                <Option key={5} value={5}>
                  起草/发送律师函
                </Option>
                <Option key={8} value={8}>
                  应收账款催款
                </Option>
                <Option key={9} value={9}>
                  代写起诉状/答辩状
                </Option>
                <Option key={6} value={6}>
                  其他咨询
                </Option>
                <Option key={7} value={7}>
                  其他
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="所属地区："
              name="AreaSelect"
              // initialValue={
              //     type === "edit"
              //         ? record.firstDemand
              //         : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择套餐",
                },
              ]}
            >
              {!this.state.isTrue ? (
                <AreaSelect
                  province={type === "edit" ? record.province : this.state.province}
                  city={type === "edit" ? record.city : this.state.city}
                  controlled={true}
                  hiddenCountry
                  Hadoop={false}
                  kunchan={true}
                  userInfo={userInfo}
                  allowClear
                  qingkong={this.state.qingkong}
                  disabled={type === "edit" ? true : false}
                  select={(province, city, county) => {
                    this.setState({
                      province: province || "",
                      city: city || "",
                    });
                  }}
                />
              ) : (
                ""
              )}
            </Form.Item>
            <Form.Item
              label="企业规模"
              name="companySpec"
              initialValue={type === "edit" ? record.companySpec : undefined}
              rules={[
                {
                  required: true,
                  message: "请选择企业规模",
                },
              ]}
            >
              <Select placeholder="请选择" allowClear style={{ width: "100%" }} disabled={type === "edit" ? true : false}>
                <Option key="1" value="1">
                  10人以下
                </Option>
                <Option key="2" value="2">
                  10-30人
                </Option>
                <Option key="3" value="3">
                  31-50人
                </Option>
                <Option key="4" value="4">
                  50人以上
                </Option>
              </Select>
            </Form.Item>
            {userRole === "1" || job == "senior" ? (
              <Form.Item
                label="业务员"
                style={{ width: "100%" }}
                name="businessUserId"
                initialValue={type === "edit" ? record.goodJsonVO.businessUserId : this.props.userInfo.userId}
                rules={[
                  {
                    required: false,
                    message: "请选择",
                  },
                ]}
              >
                <Select style={{ width: "100%" }} placeholder="请选择" allowClear disabled={type === "edit" ? true : false}>
                  <Option key={this.props.userInfo.userId} value={this.props.userInfo.userId}>
                    {this.props.userInfo.userName || this.props.userInfo.brokerName || ""}
                    （当前自己）
                  </Option>

                  {StatusList.map(v => {
                    if (this.props.userInfo.userId != v.userId)
                      return (
                        <Option key={v.userId} value={v.userId}>
                          {v.userName}
                        </Option>
                      );
                  })}
                </Select>
              </Form.Item>
            ) : null}
            {this.state.ExtraInfo.isNewUser == "N" && this.state.ExtraInfo.isShowDiscount == "0" ? (
              <Form.Item label="最终价格" style={{ width: "100%" }}>
                <p style={{ color: "red" }}>￥{this.transAmtFun("")}</p>
              </Form.Item>
            ) : (
              ""
            )}
          </Form>
        </Modal>
      </span>
    );
  }
}

let AddBroker = connect(
  state => {
    return state;
  },
  dispatch => ({
    change_operInfo: bindActionCreators(change_operInfo, dispatch),
  })
)(AddBrokerM);
